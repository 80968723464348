const LoginRoutes = [
  {
    path: "/login",
    redirect: "/login/hr_team",
    meta: { authNotRequired: true, disabledOnSignedIn: true }
  },
  {
    path: "/login/:profile_type",
    name: "login",
    component: () => import("@/login/pages/LoginView.vue"),
    meta: { authNotRequired: true, disabledOnSignedIn: true }
  },
  {
    path: "/forgot-password",
    component: () => import("@/login/ForgotPasswordView.vue"),
    meta: { authNotRequired: true, disabledOnSignedIn: true },
    children: [
      { path: "", name: "forgot_password", component: () => import("@/login/pages/ForgotPassword.vue") },
      { path: ":token", name: "forgot_password.token", component: () => import("@/login/pages/EmailTokenLanding.vue") }
    ]
  }
];

export default LoginRoutes;
