const AdminRouter = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    meta: { allowed_user_types: ["admin"] },
    component: () => import("@/admin/AdminView.vue"),
    children: [
      {
        path: "dashboard",
        name: "admin.dashboard",
        component: () => import("@/admin/pages/DashBoard.vue")
      },
      {
        path: "jobs",
        name: "admin.jobs",
        component: () => import("@/admin/pages/AdminJobs.vue")
      },
      {
        path: "candidates",
        name: "admin.candidates",
        component: () => import("@/admin/pages/AdminCandidates.vue")
      },
      {
        path: "employers",
        name: "admin.employers",
        component: () => import("@/admin/pages/AdminEmployers.vue")
      }
    ]
  }
];

export default AdminRouter;
