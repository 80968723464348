import { mapActions, mapGetters, mapMutations } from "vuex";
export const NOTIFICATIONS_MODULE_NAME = "NotificationsModule";

const initState = {
  is_notifications_channel_connected: false,
  notifications: null,
  notifications_metadata: null
};

const NotificationsModule = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    is_notifications_channel_connected: (s) => s.is_notifications_channel_connected,
    notifications: (s) => s.notifications,
    notifications_metadata: (s) => s.notifications_metadata,
    notification_count: (s) => s?.notifications?.length || 0
  },
  mutations: {
    RESET_NOTIFICATIONS_STORE: (state, object_key = "all") => {
      if (object_key === "all") {
        for (const _key in initState) state[_key] = initState[_key];
        return;
      }
      state[object_key] = initState[object_key];
    },
    notifications_channel_connected(state, flag) {
      state.is_notifications_channel_connected = flag;
    },
    all_notifications(state, { body: body, params }) {
      console.log("All Notifications");
      state.notifications = body.notifications;
      state.notifications_metadata = body.metadata;
    },
    workspace_notification(state, { body: body, params }) {
      console.log("Workspace Notifications");
      state.notifications = body.notifications;
      state.notifications_metadata = body.metadata;
    },
    update_notification(state, { body: body, params }) {
      if(body.record_type === "Applicant")
      {
        if(body.metadata.unseen_candidates > 1)
        {
          const index = state.notifications.findIndex(
            (b) => b.id === body.id
          );
          console.log("index", index);
          if (index > -1) {
            state.notifications.splice(index, 1, body);
          }
          else {
            state.notifications = [body,...state.notifications];
            state.notifications_metadata.unseen_count += 1;
          }
        }
        else// if(body.unseen_count === 1)
        {
          state.notifications = [body,...state.notifications];
          state.notifications_metadata.unseen_count += 1;
        }
      }
    },
    set_notification_event(state, { body: { status, notification }, params }) {
      if (status !== 200) {
        console.log("Some Error!");
        return;
      }

      console.log("set_notification_event : ", notification);

      const idx = state.notifications.findIndex((eachNotification) => eachNotification.id === notification.id);

      state.notifications[idx] = { ...state.notifications[idx], ...notification };
      // state.notifications.splice(idx, 1, notification);
    },
    mark_as_seen(state, { body, params }) {
      console.log("mark_as_seen body", body);
    }
  },
  actions: {}
};

export const mapNotificationsActions = (data) => mapActions(NOTIFICATIONS_MODULE_NAME, data);
export const mapNotificationsGetters = (data) => mapGetters(NOTIFICATIONS_MODULE_NAME, data);

export const mapNotificationsMutations = (data) => mapMutations(NOTIFICATIONS_MODULE_NAME, data);

export default NotificationsModule;
