import { mapActions, mapGetters, mapMutations } from "vuex";

import store from "@/global-store.index";
import router from "@/global-router";

export const SETTINGS_MODULE_NAME = "SettingsModule";

const initState = {
  is_connected: false,
  user_settings: null,
  oldSettingsCopy: null,
  modalComponent: null,
  showModal: false,
  fieldsToResetOnCancel: null,
  twoFAData: null,
  isUsernameAvailable: null,
};

const SettingsModule = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    is_connected: (s) => s.is_connected,
    user_settings: (s) => s.user_settings,
    oldSettingsCopy: (s) => s.oldSettingsCopy,
    modalComponent: (s) => s.modalComponent,
    showModal: (s) => s.showModal,
    fieldsToResetOnCancel: (s) => s.fieldsToResetOnCancel,
    twoFAData: (s) => s.twoFAData,
    isUsernameAvailable: (s) => s.isUsernameAvailable,
    getState: (s) => (key) => s[key],
    slackCredentialsPresent: (s) =>
      s.user_settings?.security_settings?.slack_credentials
        ?.credentials_present || false,
  },
  mutations: {
    RESET: (state, object_key = "all") => {
      if (object_key === "all") {
        for (const _key in initState) state[_key] = initState[_key];
        return;
      }
      state[object_key] = initState[object_key];
    },

    connected(state, flag) {
      state.is_connected = flag;
    },
    /* edit(state, { body: basic_settings, params }) {
      state.basic_settings = basic_settings;
    },*/
    index_setting(state, { body: settings, params }) {
      // console.log('Current user_settings:', state.user_settings);
      // console.log('New settings:', settings);
      state.user_settings = settings;
      // console.log('Updated user_settings:', state.user_settings);
    },
    update(state, { body: _settings, params }) {
      console.log("Settings Update", _settings);
      const current_theme = store.getters["AppModule/current_theme"];
      if (current_theme !== _settings.preferred_theme) {
        store.commit(
          "AppModule/set_preferred_theme",
          _settings.preferred_theme
        );
        store.commit("AppModule/set_theme", _settings.preferred_theme);
      }
      //if (state[params.type] === _settings) return;
      //state[params.type] = _settings;
    },
    setOldSettingsCopy(state, _copy) {
      state.oldSettingsCopy = _copy;
    },
    change_two_factor_auth(state, { body: response, params }) {
      console.log("change_two_factor_auth : ", response);
      if (response.code < 200 && response.code > 299) {
        state.twoFAData.frontendStep = -1;
        console.log("Some Issue : ", response?.message || response?.error);
        return;
      }
      if (params.step === "E1") {
        state.twoFAData = {
          current_step: params.step,
          next_step: response?.next_step,
          next_step_params: response?.next_request?.params,
          qrcode_url: response?.qrcode_url,
          confirmation_otp: "",
          frontendStep: 1,
        };

        state.modalComponent = "TwoFAFlow";
        state.showModal = true;
        state.fieldsToResetOnCancel = params.fieldsToResetOnCancel;
      } else if (params.step === "E2") {
        state.twoFAData.frontendStep = 3;
        console.log("USer Settings", this.user_settings);
      } else if (params.step === "D") {
        setTimeout(() => {
          router.go();
        }, 2000);
      }
    },
    closeModal(state) {
      setTimeout(() => {
        state.modalComponent = null;
      }, 500);
      state.showModal = false;
    },
    setOTP(state, otp) {
      state.twoFAData.confirmation_otp = otp;
    },
    increaseFrontEndStep(state) {
      state.twoFAData.frontendStep++;
    },
    is_user_name_available(state, { body, params }) {
      console.log("Is Username Available : ", body);
      state.isUsernameAvailable = body;
    },
    clearSlackCredentials(state) {
      if (state.user_settings?.security_setting?.slack_credentials_flag) {
        state.user_settings.security_setting = {
          slack_credentials_flag: false,
        };
      }
    },
  },
  actions: {
    async clearSlackCredentials({ commit }) {
      commit("clearSlackCredentials");
    },
    async send_email_verification_code({ commit, rootGetters }, params) {
      const send_email_verification_code_url = new URL(
        "login/send_verification_code",
        rootGetters["LoginStore/base_url"]
      );
      const frontendVerificationLink = new URL(
        "/#/email-verification-callback",
        rootGetters["LoginStore/base_url"]
      );

      const promise = await fetch(send_email_verification_code_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ link: frontendVerificationLink }),
      });

      promise.json().then((response) => {
        console.log("Response : ", response);
        if (response.status === 200) {
          commit(
            "LoginStore/setState",
            {
              key: "show_awn",
              value: { type: "success", message: response.message },
            },
            { root: true }
          );
        } else if (response.status === 401)
          commit(
            "LoginStore/setState",
            {
              key: "show_awn",
              value: { type: "alert", message: response.message },
            },
            { root: true }
          );
        else
          commit(
            "LoginStore/setState",
            {
              key: "show_awn",
              value: { type: "alert", message: response.message },
            },
            { root: true }
          );
        // Handle the response here
      });
    },
  },
};

export const mapSettingsActions = (data) =>
  mapActions(SETTINGS_MODULE_NAME, data);
export const mapSettingsGetters = (data) =>
  mapGetters(SETTINGS_MODULE_NAME, data);

export const mapSettingsMutations = (data) =>
  mapMutations(SETTINGS_MODULE_NAME, data);

export default SettingsModule;
