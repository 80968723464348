const EmployerRoutes = [
  {
    path: "/employer",
    /*redirect: "/employer/dashboard",*/
    meta: { allowed_user_types: ["employer"] },
    component: () => import("@/employer/Employer.vue"),
    children: [
      {
        path: "dashboard",
        name: "employer.dashboard",
        component: () => import("@/employer/pages/EmployerDashboard.vue")
      }
    ]
  }
];

export default EmployerRoutes;
