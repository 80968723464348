import { mapActions, mapGetters, mapMutations } from "vuex";

export const ADMIN_MODULE_NAME = "AdminModule";

const initState = {
  is_connected: false,
  admin_employers: null,
  admin_jobs: null,
  popular_jobs: null,
  admin_candidates: null,
  popular_candidates: null,
  overview: null,
  filter_options: null,
  loadCounter : 0
};

const AdminModule = {
  namespaced: true,
  state: () => initState,
  getters: {
    is_connected: (s) => s.is_connected,
    admin_employers: (s) => s.admin_employers,
    admin_jobs: (s) => s.admin_jobs,
    admin_candidates: (s) => s.admin_candidates,
    popular_jobs: (s) => s.popular_jobs,
    popular_candidates: (s) => s.popular_candidates,
    popular_employers: (s) => s.popular_employers,
    overview: (s) => s.overview,
    filter_options: (s) => s.filter_options,
    isLoading: (s) => s.loadCounter<4
  },
  mutations: {
    connected(state, flag) {
      state.is_connected = flag;
    },

    index_employer(state, { body: admin_employers, params }) {
      state.admin_employers = admin_employers;
      state.loadCounter++
    },
    index_job(state, { body: admin_jobs, params }) {
      state.admin_jobs = admin_jobs;
        state.loadCounter++;

    },
    index_candidate(state, { body: admin_candidates, params }) {
      state.admin_candidates = admin_candidates;
        state.loadCounter++;
    },
    top_jobs(state, { body: _jobs, params }) {
      state.popular_jobs = _jobs;
    },
    top_candidates(state, { body: _candidates, params }) {
      state.popular_candidates = _candidates;
    },
    top_employers(state, { body: _employers, params }) {
      state.popular_employers = _employers;
    },
    overview_score(state, { body: overview, params }) {
      state.overview = overview;
        state.loadCounter++;
    },
    job_filter_options(state, { body: filter_options, params }) {
      state.filter_options = filter_options;
    },
    employer_filter_options(state, { body: filter_options, params }) {
      state.filter_options = filter_options;
    },
    candidate_filter_options(state, { body: filter_options, params }) {
      state.filter_options = filter_options;
    }
  },
  actions: {}
};

export const mapAdminActions = (data) => mapActions(ADMIN_MODULE_NAME, data);
export const mapAdminGetters = (data) => mapGetters(ADMIN_MODULE_NAME, data);

export const mapAdminMutations = (data) => mapMutations(ADMIN_MODULE_NAME, data);

export default AdminModule;
