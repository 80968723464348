const NotificationsRoutes = [
  {
    path: "/notifications",
    meta: { allowed_user_types: ["all"] },
    component: () => import("@/notification/NotificationsView.vue"),
    children: [{ path: "", name: "notifications.index", component: () => import("@/notification/pages/AllNotifications.vue") }]
  }
];

export default NotificationsRoutes;
