import router from "@/global-router";
import store from "@/global-store.index";

const initState = {
  is_signed_in: false,
  details_fetched: false,
  base_url: null,
  locked: null,
  email: null,
  route_details: null,
  user_type: null,
  user_current_role: null,
  user_permissions: null,
  user_details: null,
  show_awn: null,
  curr_login_stage: 0,
  redirect_mapping: {
    hr_team: "hr-company.dashboard",
    employer: "employer.dashboard",
    admin: "admin.dashboard",
    candidate: "candidate.dashboard",
  },
  forgot_password_email: null,
  forgot_password_options: null,
  forgot_password_step: 0,
  response_loading: false,
};

const LoginStore = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    is_signed_in: (s) => s.is_signed_in,
    base_url: (s) => s.base_url,
    locked: (s) => s.locked,
    email: (s) => s.email,
    user_type: (s) => s.user_type,
    user_current_role: (s) => s.user_current_role,
    user_details: (s) => s.user_details,
    route_details: (s) => s.route_details,
    details_fetched: (s) => s.details_fetched,
    redirect_mapping: (s) => s.redirect_mapping,
    user_permissions: (s) => s.user_permissions,
    show_awn: (s) => s.show_awn,
    curr_login_stage: (s) => s.curr_login_stage,
    forgot_password_email: (s) => s.forgot_password_email,
    forgot_password_options: (s) => s.forgot_password_options,
    forgot_password_step: (s) => s.forgot_password_step,
    response_loading: (s) => s.response_loading,
  },
  mutations: {
    RESET: (state, object_key = "all") => {
      if (object_key === "all") {
        for (const _key in initState) state[_key] = initState[_key];
        state.base_url = window.location.protocol + "//" + window.location.host;
        return;
      }
      state[object_key] = initState[object_key];
      state.base_url = window.location.protocol + "//" + window.location.host;
    },

    login_success(state, { response, query: _query }) {
      state.is_signed_in = true;
      state.base_url = response.host;
      state.email = response.email;
      state.user_type = response.user_type;

      const default_redirect = state.redirect_mapping[response.user_type];
      let goTo = { type: "name", to: default_redirect };
      if (_query)
        goTo = _query.redirect
          ? { type: "path", to: _query.redirect }
          : { type: "name", to: default_redirect };
      if (goTo.to.includes("/login/") || goTo.to === "/")
        goTo = { type: "name", to: default_redirect };
      router.replace({ [goTo.type]: goTo.to }).then((r) => {});
    },
    setState(state, { key, value }) {
      state[key] = value;
    },
  },
  actions: {
    async fetchDetails({ commit, getters }) {
      const new_info_url = new URL("login/new", getters.base_url);

      const promise = await fetch(new_info_url, {
        method: "GET",
        mode: "no-cors",
      });

      promise.json().then((response) => {
        commit("setState", { key: "base_url", value: response.host });
        commit("setState", {
          key: "user_details",
          value: response.user_details,
        });
        store.commit("AppModule/set_preferred_theme", response.preferred_theme);

        if (response.signed_in) {
          commit("setState", { key: "details_fetched", value: true });
          commit("login_success", {
            response,
            query: getters.route_details?.query,
          });
        }

        if (response.user_type === "employer") {
          commit("setState", {
            key: "user_permissions",
            value: response?.user_employer?.permissions,
          });
          commit("setState", {
            key: "user_current_role",
            value: response?.user_employer.roles[0],
          });
        }
        if (response.user_type === "hr_team") {
          commit("setState", {
            key: "user_permissions",
            value: response?.user_details?.current_workspace_details?.details?.role_permission_obj?.role_combined_permissions,
          });
          commit("setState", {
            key: "user_current_role",
            // value: response?.user_details?.current_workspace_details?.details?.role_permission_obj?.role_id,
            value: response?.user_details?.current_workspace_details?.details?.role_permission_obj?.role_name,
          });
        }
      });
    },

    async registerUserAction({ commit, getters }, userData) {
      const register_url = new URL(
        "/login/hr_company_signup",
        getters.base_url
      );

      const promise = await fetch(register_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      promise
        .json()
        .then((response) => {
          console.log("Response : ", response);
          if (response.code === 202) {
            commit("setState", {
              key: "show_awn",
              value: { type: "success", message: response.message },
            });
            console.log("Registration successful:", response.message);
          } else {
            console.error("Registration error:", response.message);
            commit("setState", {
              key: "show_awn",
              value: { type: "alert", message: response.message },
            });
          }
        })
        .catch((error) => {
          console.error("Error registering user:", error);
        });
    },

    async login({ dispatch, commit, getters }, params) {
      const login_data = {
        login: {
          step: params?.step,
          email: params?.email,
          password: params?.password,
          user_type: params?.user_type,
        },
      };

      if (params.step === "E") {
        if (!params.otp_code) {
          this.$awn.alert("Please Enter OTP!");
          return;
        }
        login_data.login.otp_code = params.otp_code;
      }

      const login_url = new URL("login", getters.base_url);
      const promise = await fetch(login_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(login_data),
      });

      await promise.json().then((response) => {
        // console.log("Response : ", response);
        if (response.code === 202) dispatch("fetchDetails");
        else if (response.code === 200) {
          commit("setState", { key: "curr_login_stage", value: 1 });
        } else if (response.code >= 400 && response.code < 500) {
          commit("setState", {
            key: "show_awn",
            value: { type: "alert", message: response.message },
          });
        }
      });
    },

    async logout({ commit, dispatch, getters }) {
      const logout_url = new URL("login", getters.base_url);

      const promise = await fetch(logout_url, {
        method: "DELETE",
      });

      promise.json().then((response) => {
        if (response.code === 200) {
          commit("RESET");
          dispatch("fetchDetails");
          router.push("/login");
        }
      });
    },
    async switchWorkspace({ commit, dispatch, getters }, params) {
      const switchData = { user_type: params.user_type };
      if (params.user_type === "employer")
        switchData.user_workspace_id = params.id;

      if (params.user_type === "hr_team")
        switchData.user_workspace_id = params.id;

      const switch_workspace_url = new URL(
        "login/switch_workspace",
        getters.base_url
      );

      const promise = await fetch(switch_workspace_url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(switchData),
      });

      promise.json().then((response) => {
        if (response.code === 200) router.go();
      });
    },
    async changePassword({ commit, dispatch, getters }, params) {
      const change_password_url = new URL(
        "login/change_password",
        getters.base_url
      );

      const promise = await fetch(change_password_url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      promise.json().then((response) => {
        if (response.status === 200) {
          commit("setState", {
            key: "show_awn",
            value: { type: "success", message: response.message },
          });
          setTimeout(() => {
            dispatch("logout");
          }, 200);
        } else if (response.status === 401)
          commit("setState", {
            key: "show_awn",
            value: { type: "alert", message: response.message },
          });
        else
          commit("setState", {
            key: "show_awn",
            value: { type: "alert", message: response.message },
          });
        // Handle the response here
      });
    },
    async checkTokenValidation({ commit, getters }, params) {
      const checkValidationURL = new URL(
        "/login/forgot_password",
        getters.base_url
      );
      commit("setState", { key: "response_loading", value: true });

      const promise = await fetch(checkValidationURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      promise.json().then((response) => {
        console.log(
          "Response : ",
          response.status >= 400 || response.status < 500
        );
        if (response.status >= 400 && response.status < 500) {
          commit("setState", {
            key: "show_awn",
            value: { type: "alert", message: response.message },
          });
          router.push("/login");
        }
      });

      commit("setState", { key: "response_loading", value: false });
    },
    async forgotPassword({ commit, getters, dispatch }, params) {
      const forgot_password_url = new URL(
        "/login/forgot_password",
        getters.base_url
      );

      commit("setState", { key: "response_loading", value: true });

      const promise = await fetch(forgot_password_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      promise.json().then((response) => {
        console.log("Response : ", response);
        if (response.status === 401)
          commit("setState", {
            key: "show_awn",
            value: { type: "alert", message: response.message },
          });
        else if (
          getters.forgot_password_step === 0 &&
          response.status === 200
        ) {
          commit("setState", {
            key: "forgot_password_options",
            value: response.options,
          });
          commit("setState", { key: "forgot_password_step", value: 1 });
        } else if (
          getters.forgot_password_step === 1 &&
          response.status === 200
        ) {
          commit("setState", {
            key: "show_awn",
            value: { type: "success", message: response.message },
          });
          setTimeout(() => {
            router.push("/login");
          }, 1000);
        } else if (getters.forgot_password_step === 2) {
        }
      });

      commit("setState", { key: "response_loading", value: false });
    },
    async newPassword({ commit, getters, dispatch }, params) {
      const new_password_url = new URL("/login/new_password", getters.base_url);

      const promise = await fetch(new_password_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      promise.json().then((response) => {
        console.log("Response : ", response);
        if (response.status === 200) {
          commit("setState", {
            key: "show_awn",
            value: { type: "success", message: response.message },
          });
          setTimeout(() => {
            router.push("/login");
          }, 1000);
        } else if (response.status >= 400 && response.status < 500) {
          console.log("Error");
          commit("setState", {
            key: "show_awn",
            value: { type: "alert", message: response.message },
          });
        }
      });
    },
  },
};

export default LoginStore;
