import { createStore } from "vuex";

import JobsModule from "@/jobs/jobs.vuex";
import SchedulerModule from "@/scheduler/store/scheduler.vuex";
import CandidateModule from "@/candidate/candidates.vuex";
import InterviewModule from "@/interview/store/interview.vuex";
import EmployerModule from "@/employer/employer.vuex";
import HRCompanyModule from "@/hr-company/hr.vuex";
import HRJobsModule from "@/hr-employerjobs/hr-job.vuex";
import ProfileEditModule from "@/profile-edit/profile-edit.vuex";
import AppModule from "@/entrypoints/app.vuex";
import NewJobModule from "@/jobs/new-job.vuex";
import AdminModule from "@/admin/admin.vuex";
import LoginStore from "@/login/login.vuex";
import SettingsModule from "@/settings/settings.vuex";
import NotificationsModule from "@/notification/notification.vuex";

const state = {};

const getters = {};

const mutations = {};
export default createStore({
  state,
  getters,
  mutations,
  modules: {
    JobsModule,
    SchedulerModule,
    CandidateModule,
    InterviewModule,
    AppModule,
    EmployerModule,
    HRCompanyModule,
    HRJobsModule,
    ProfileEditModule,
    NewJobModule,
    AdminModule,
    LoginStore,
    SettingsModule,
    NotificationsModule
  }
});
