const HrJobRouter = [
  {
    path: "/job/:job_hashid",
    meta: { allowed_user_types: ["hr_team"] },
    component: () => import("@/hr-employerjobs/pages/HrJobs.vue"),
  },
];

// export const HRCandidateRoutes = [
//   {
//     path: "candidates",
//     name: "candidates.manage",
//     meta: {
//       allowed_user_types: ["hr_team"],
//       breadcrumb_name: "Manage Candidates",
//     },
//     component: () =>
//       import("@/candidate/pages/CandidatesIndexPage.vue"),
//   },
//   {
//     path: "candidates/:candidate_id",
//     meta: { allowed_user_types: ["hr_team"] },
//     component: () => import("@/candidate/pages/Candidate.vue"),
//     children: [
//       {
//         path: "",
//         name: "candidates.show",
//         component: () => import("@/candidate/pages/CandidateProfilePage.vue"),
//       },
//       {
//         path: "schedule",
//         name: "candidate.interview.schedule",
//         component: () => import("@/scheduler/pages/CommonScheduler.vue"),
//       },
//       {
//         path: "schedule",
//         name: "candidate.interview.schedule",
//         component: () => import("@/scheduler/pages/CommonScheduler.vue"),
//       },
//       {
//         path: "interview",
//         name: "candidate.interview.show",
//         component: () => import("@/candidate/pages/CandidateInterview.vue"),
//         meta: {},
//       },
//     ],
//   },
// ];

export default HrJobRouter;
