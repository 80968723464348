import { mapActions, mapGetters, mapMutations } from "vuex";

export const JOBS_MODULE_NAME = "HRJobsModule";

const initState = {
  is_connected: false,
  jobs: null,
  filter_options: null,
  selected_job: null,
  selected_job_hashid: null,
  selected_candidate: null,
  selected_employer: null,
  job_candidates: [],
  text_notes: [],
  selected_note: null,
  employer_id: null,
  isLoading: false,
};

const HRJobsModule = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    is_connected: (s) => s.is_connected,
    jobs: (s) => s.jobs,
    filter_options: (s) => s.filter_options,
    selected_job: (s) => s.selected_job,
    employer_id: (s) => s.employer_id,
    selected_job_hashid: (s) => s.selected_job_hashid,
    selected_candidate: (s) => s.selected_candidate,
    selected_employer: (s) => s.selected_employer,
    job_candidates: (s) => s.job_candidates,
    text_notes: (s) => s.text_notes,
    selected_note: (s) => s.selected_note,
    isLoading: (s) => s.isLoading,
    jobByID: (s) => (id_to_match) => {
      if (s.jobs.length === 0) return null;
      if (!id_to_match) return s.jobs[0];
      return s.jobs.find((job) => job.job_hashid === id_to_match);
    },
  },
  mutations: {
    RESET: (state, object_key = "all") => {
      if (object_key === "all") {
        for (const _key in initState) state[_key] = initState[_key];
        return;
      }
      state[object_key] = initState[object_key];
    },
    connected(state, flag) {
      state.is_connected = flag;
    },
    setLoading(state, flag) {
      state.isLoading = flag;
    },
    job_filter_options(state, { body: filter_options, params }) {
      state.filter_options = filter_options;
    },
    //List All Jobs
    index(state, { body: jobs, params }) {
      state.jobs = jobs;
      state.isLoading = false;
    },
    index_candidate_jobs(state, { body: candidate_jobs, params }) {
      state.jobs = candidate_jobs;
    },
    index_job_candidates(state, { body: job_candidates, params }) {
      state.job_candidates = job_candidates;
    },
    //Create Jobs
    create(state, { body: job, params }) {
      state.jobs = [...state.jobs, job];
      state.selected_job = job || null;

      //Sorting
      state.jobs.sort((a, b) => new Date(b.postedOn) - new Date(a.postedOn));
    },
    //Single Job
    edit(state, { body: job, params }) {
      state.selected_job = job;
      console.log("Employer ID", params.employer_id);
    },
    //Delete Job
    destroy(state, { body: job, params }) {
      //state.employer_id = params.employer_id;
      const index = state.jobs.findIndex(
        (b) => b.job_hashid === job.job_hashid
      );
      if (index > -1) {
        state.jobs.splice(index, 1);
      }

      state.selected_job = null;
    },
    //Update Job
    update(state, { body: job, params }) {
      // console.log("employer_id:", params.employer_id);
      // state.employer_id = params.employer_id;
      // console.log("State employer_id:", state.employer_id);

      const index = state.jobs.findIndex(
        (b) => b.job_hashid === job.job_hashid
      );
      console.log("index", index);
      if (index > -1) {
        state.jobs.splice(index, 1, job);
      }
      state.selected_job = job || null;
    },
    get_candidate(state, { body: selected_candidate, params }) {
      state.selected_candidate = selected_candidate;
      state.isLoading = false;
    },
    fetch_employer(state, { body: selected_employer, params }) {
      state.selected_employer = selected_employer;
    },
    apply_for_job(state, { body: job, params }) {
      const job_idx = state.jobs.findIndex(
        (e) => e.job_hashid === job.job_hashid
      );
      state.jobs.splice(job_idx, 1, job);
    },
    unapply_for_job(state, { body: job, params }) {
      const job_idx = state.jobs.findIndex(
        (e) => e.job_hashid === job.job_hashid
      );
      state.jobs.splice(job_idx, 1, job);
    },
    update_candidate_status(state, { body: candidate, params }) {
      state.selected_candidate = candidate;
    },
    auto_scheduling_for_job(state, { body, params }) {
      console.log("Jobs Scheduled");
    },
    //notes
    index_candidate_job_notes(state, { body: text_notes, params }) {
      state.text_notes = text_notes;
    },
    // update_candidate_job_notes(state, { body: note, params }) {
    //   const index = state.text_notes.findIndex(
    //     (b) => b.note_id === note.note_id
    //   );
    //   console.log("index", index);
    //   if (index > -1) {
    //     state.text_notes.splice(index, 1, note);
    //   }
    //   state.selected_note = note || null;
    // },
    // destroy_candidate_job_notes(state, { body: note, params }) {
    //   const index = state.text_notes.findIndex(
    //     (b) => b.note_id === note.note_id
    //   );
    //   console.log("index", index);
    //   if (index > -1) {
    //     state.text_notes.splice(index, 1, note);
    //   }

    //   state.selected_note = null;
    // },
    create_candidate_job_notes(state, { body: note, params }) {
      state.text_notes = [...state.text_notes, note];
      state.selected_note = note || null;

      //Sorting
      state.text_notes.sort(
        (a, b) => new Date(b.postedOn) - new Date(a.postedOn)
      );
    },
  },
  actions: {
    set_selected_job_hashid({ state }, job_hashid) {
      state.selected_job_hashid = job_hashid;
    },
    set_selected_job({ state, getters }, job_hashid) {
      console.log("Setting Job");
      state.selected_job = { ...getters.jobByID(job_hashid) };
      console.log(getters.selected_job);
    },
    // set_selected_employer({ state }, employer_id) {
    //   state.selected_employer = employer_id;
    // },
  },
};

export const mapJobsActions = (data) => mapActions(JOBS_MODULE_NAME, data);
export const mapJobsGetters = (data) => mapGetters(JOBS_MODULE_NAME, data);

export const mapJobsMutations = (data) => mapMutations(JOBS_MODULE_NAME, data);

export default HRJobsModule;
