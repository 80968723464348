const CandidateJobsRoutes = [
  {
    path: "/candidate-jobs",
    meta: { allowed_user_types: ["candidate"] },
    component: () => import("./CandidateJobs.vue"),
    children: [
      {
        path: "",
        name: "candidate-jobs.index",
        component: () => import("././pages/CandidateJobsIndex.vue")
      },
      {
        path: "applied",
        name: "candidate-jobs.applied",
        component: () => import("././pages/CandidateJobsIndex.vue")
      },
      {
        path: ":job_hashid",
        name: "candidate-jobs.view",
        component: () => import("././pages/CandidateJobsView.vue")
      }
    ]
  },
  {
    path: "/dashboard",
    name: "candidate.dashboard",
    meta: { allowed_user_types: ["candidate"] },
    component: () => import("@/candidate/pages/CandidateDashboard.vue")
  }
];

export default CandidateJobsRoutes;
