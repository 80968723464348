const ProfileRoutes = [
  {
    path: "/profile",
    component: () => import("@/profile-edit/pages/ProfileRouter.vue"),
    meta: { allowed_user_types: ["all"] },
    children: [
      {
        path: "",
        name: "user.profile",
        component: () => import("@/profile-edit/pages/UserProfile.vue")
      },
      {
        path: "calendar",
        name: "user.calendar",
        component: () => import("@/profile-edit/pages/UserCalendar.vue")
      }
    ]
  }
];

export default ProfileRoutes;
