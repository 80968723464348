const SettingsRoutes = [
  {
    path: "/settings",
    meta: { allowed_user_types: ["all"], breadcrumb_name: "Settings" },
    component: () => import("@/settings/SettingsLayout.vue"),
    children: [
      {
        path: "basic",
        component: () => import("@/settings/SettingsView.vue"),
        children: [
          {
            path: "",
            name: "settings.basic",
            component: () => import("@/settings/pages/SettingsBasic.vue")
          },
          {
            path: ":setting_name",
            name: "settings.basic.each",
            component: () => import("@/settings/pages/SettingsNested.vue")
          }
        ]
      },
      {
        path: "security",
        component: () => import("@/settings/SettingsView.vue"),
        children: [
          { path: "", name: "settings.security", component: () => import("@/settings/pages/SettingsSecurity.vue") },
          {
            path: ":setting_name",
            name: "settings.security.each",
            component: () => import("@/settings/pages/SettingsNested.vue")
          }
        ]
      },
      {
        path: "visibility",
        component: () => import("@/settings/SettingsView.vue"),
        children: [
          { path: "", name: "settings.visibility", component: () => import("@/settings/pages/SettingsVisibility.vue") },
          {
            path: ":setting_name",
            name: "settings.visibility.each",
            component: () => import("@/settings/pages/SettingsNested.vue")
          }
        ]
      },
      {
        path: "privacy",
        component: () => import("@/settings/SettingsView.vue"),
        children: [
          { path: "", name: "settings.privacy", component: () => import("@/settings/pages/SettingsDataPrivacy.vue") },
          {
            path: ":setting_name",
            name: "settings.privacy.each",
            component: () => import("@/settings/pages/SettingsNested.vue")
          }
        ]
      },
      {
        path: "notifications",
        component: () => import("@/settings/SettingsView.vue"),
        children: [
          { path: "", name: "settings.notifications", component: () => import("@/settings/pages/SettingsNotifications.vue") },
          {
            path: ":setting_name",
            name: "settings.notifications.each",
            component: () => import("@/settings/pages/SettingsNested.vue")
          }
        ]
      },
      {
        path: "roles",
        component: () => import("@/settings/SettingsView.vue"),
        meta: {
          allowed_user_types: ["employer", "hr_team"],
          allowed_roles: ["admin", "co_admin"]
        },
        children: [{ path: "", name: "settings.roles", component: () => import("@/settings/pages/SettingsRoles.vue") }]
      }
    ]
  }
];

export default SettingsRoutes;
