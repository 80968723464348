import { mapActions, mapGetters, mapMutations } from "vuex";

export const EMPLOYER_MODULE_NAME = "EmployerModule";

const initState = {
  is_connected: false,
  employers: [],
  selected_employer: null,
  dashboard: null,
  isLoading: true
};

const EmployerModule = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    is_connected: (s) => s.is_connected,
    employers: (s) => s.employers,
    selected_employer: (s) => s.selected_employer,
    dashboard: (s) => s.dashboard,
    isLoading: (s) => s.isLoading
  },
  mutations: {
    RESET: (state, object_key = "all") => {
      if (object_key === "all") {
        for (const _key in initState) state[_key] = initState[_key];
        return;
      }
      state[object_key] = initState[object_key];
    },

    connected(state, flag) {
      state.is_connected = flag;
    },
    //List All employers
    index(state, { body: employers, params }) {
      state.employers = employers;
    },
    edit(state, { body: employer, params }) {
      state.selected_employer = employer;
      console.log(employer);
    },
    update(state, { body: employer, params }) {
      const index = state.employers.findIndex((b) => b.id === employer.id);
      if (index > -1) state.employers.splice(index, 1, employer);
      if (state.selected_employer && employer.id === state.selected_employer.id) state.selected_employer = employer;
    },
    index_dashboard(state, { body: dashboard, params }) {
      state.dashboard = dashboard;
      state.isLoading = false;
    }
  },
  actions: {}
};

export const mapEmployerActions = (data) => mapActions(EMPLOYER_MODULE_NAME, data);
export const mapEmployerGetters = (data) => mapGetters(EMPLOYER_MODULE_NAME, data);

export const mapEmployerMutations = (data) => mapMutations(EMPLOYER_MODULE_NAME, data);

export default EmployerModule;
