const CandidateRouter = [
  {
    path: "/candidate/:candidate_id",
    meta: { allowed_user_types: ["hr_team","employer", "admin"] },
    component: () => import("@/candidate/pages/CandidateStandalone.vue")
  }
];

export const JobCandidateRouter = [
  {
    path: "candidates",
    name: "candidates.index",
    meta: { allowed_user_types: ["employer", "admin"], breadcrumb_name: "Manage Candidates" },
    component: () => import("././pages/CandidatesIndexPage.vue")
  },
  {
    path: "candidates/:candidate_id",
    meta: { allowed_user_types: ["employer", "admin", "hr_team"] },
    component: () => import("@/candidate/pages/Candidate.vue"),
    children: [
      { path: "", name: "candidates.show", component: () => import("././pages/CandidateProfilePage.vue") },
      {
        path: "schedule",
        name: "candidate.interview.schedule",
        component: () => import("@/scheduler/pages/CommonScheduler.vue")
      },
      {
        path: "schedule",
        name: "candidate.interview.schedule",
        component: () => import("@/scheduler/pages/CommonScheduler.vue")
      },
      {
        path: "interview",
        name: "candidate.interview.show",
        component: () => import("././pages/CandidateInterview.vue"),
        meta: {}
      }
    ]
  }
];
export default CandidateRouter;
