import { mapActions, mapGetters, mapMutations } from "vuex";
import { InterviewProcessOptions } from "@/assets/InterviewProcessOptions";

export const INTERVIEW_MODULE_NAME = "InterviewModule";

export const namespaced = true;

const initState = {
  templates: [
    {
      name: "VueJS Developer",
      job_hashid: "vuejs-developer",
      process: [
        {
          name: "Analyse Resume",
          id: "analyse-resume"
        },
        {
          name: "Ask Preliminary Questions",
          id: "prelim-questions"
        },
        {
          name: "HR Pages",
          id: "hr-Pages"
        },
        {
          name: "Technical Pages - FrontEnd",
          id: "technical-Pages-frontend"
        }
      ]
    }
  ],
  InterviewProcessOptions,
  is_connected: false,
  selected_template: null,
  changes_made: false,
  data_saved: false,
  snapshot: null
};

const InterviewModule = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    templates: (s) => s.templates,
    is_connected: (s) => s.is_connected,
    interviewProcessOptions: (s) => s.InterviewProcessOptions,
    selected_template: (s) => s.selected_template,
    changes_made: (s) => s.changes_made,
    data_saved: (s) => s.data_saved,
    snapshot: (s) => s.snapshot,

    getSingleTemplate: (s) => (payload) => {
      if (!payload) return s.templates[0];
      return s.templates.find((template) => template.job_hashid === payload);
    },
    getTemplateIndex: (s) => (template_id) => {
      let confirmedIDX = -1;

      for (let i = 0; i < s.templates.length; i++)
        if (s.templates[i].job_hashid === template_id) {
          confirmedIDX = i;
          break;
        }

      return confirmedIDX;
    }
  },
  mutations: {
    RESET: (state, object_key = "all") => {
      if (object_key === "all") {
        for (const _key in initState) state[_key] = initState[_key];
        return;
      }
      state[object_key] = initState[object_key];
    },
    connected(state, flag) {
      state.is_connected = flag;
    },
    //List All Jobs
    index(state, { body: templates, params }) {
      state.templates = templates;
    },
    create: (state, { body: template, params }) => {
      state.templates.push(template);
    },
    edit(state, { body: template, params }) {
      state.selected_template = template || null;
    },
    destroy: (state, { body: template, params }) => {
      state.templates.splice(params.index, 1);
    },
    update: (state, { body: template, params }) => {
      console.log("Updating On VueX");
    },
    update_process: (state, params) => {
      let confirmedIDX = 0;

      for (let i = 0; i < state.templates.length; i++)
        if (state.templates[i].job_hashid === params.template_id) {
          confirmedIDX = i;
          break;
        }

      switch (params.intent) {
        case "add":
          state.templates[confirmedIDX].process = params.process;
          state.changes_made = true;
          break;

        case "remove":
          state.templates[confirmedIDX].process.splice(params.index, 1);
          state.changes_made = true;
          break;

        default:
          console.log("No Intent Provided!");
      }
    },
    reset_template(state, { index }) {
      state.templates.splice(index, 1, state.snapshot);
      state.changes_made = false;
    },
    toggle_state(state, { state_name, set = null }) {
      if (set == null) state[state_name] = !state[state_name];
      else state[state_name] = set;
    },
    set_snapshot(state, snapshot) {
      state.snapshot = snapshot;
    }
  },
  actions: {}
};

export const mapInterviewActions = (data) => mapActions(INTERVIEW_MODULE_NAME, data);
export const mapInterviewGetters = (data) => mapGetters(INTERVIEW_MODULE_NAME, data);

export const mapInterviewMutations = (data) => mapMutations(INTERVIEW_MODULE_NAME, data); //mapMutation("name" Array)

export default InterviewModule;
