// eslint-disable-next-line no-unused-vars
import { JobCandidateRouter } from "@/candidate/candidate.router";
const JobsRoutes = [
  {
    path: "/job",
    redirect: "/jobs",
    meta: {
      allowed_user_types: ["employer", "admin"],
      breadcrumb_name: "All Jobs",
    },
    component: () => import("./pages/Jobs.vue"),
    children: [
      {
        path: "/jobs",
        name: "jobs.index",
        component: () => import("././pages/JobsIndexView.vue"),
      },
      {
        path: ":job_hashid",
        component: () => import("./pages/Jobs.vue"),
        meta: { breadcrumb_name: ":job_title" },
        children: [
          {
            path: "",
            name: "jobs.edit",
            component: () => import("./pages/JobsEditView.vue"),
          },
          ...JobCandidateRouter,
        ],
      },
      {
        path: "new",
        name: "jobs.new",
        component: () => import("././pages/JobAIGenerate.vue"),
      },
    ],
  },
];

export default JobsRoutes;
