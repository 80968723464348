const JobsViewRoutes = [
  {
    path: "/jobs_view/:job_hashid",
    name: "jobs-view.view",
    meta: { authNotRequired: true },
    component: () => import("@/jobs-view/pages/JobsView.vue"),
  },
  {
    path: "/login/new_user",
    name: "new_user_login",
    meta: { authNotRequired: true, disabledOnSignedIn: true },
    component: () => import("@/jobs-view/components/NewLogin.vue"),
  },
];

export default JobsViewRoutes;
