import { mapActions, mapGetters, mapMutations } from "vuex";

export const CANDIDATE_MODULE_NAME = "CandidateModule";

const initState = {
  is_connected: false,
  isLoading: false,
  candidates: [],
  candidate_jobs: null,
  selected_job_hashid: null,
  selected_job: null,
  job_filters: null,
  selected_candidate: null,
  candidate_profile_status: false,
  suggested_jobs: null,
  calendar_events: [],
  selected_event: null,
  upcoming_meetings: null,
  dashboard: null,
};

const CandidateModule = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    is_connected: (s) => s.is_connected,
    isLoading: (s) => s.isLoading,
    candidates: (s) => s.candidates,
    dashboard: (s) => s.dashboard,
    candidate_jobs: (s) => s.candidate_jobs,
    selected_job_hashid: (s) => s.selected_job_hashid,
    selected_job: (s) => s.selected_job,
    job_filters: (s) => s.job_filters,
    selected_candidate: (s) => s.selected_candidate,
    suggested_jobs: (s) => s.suggested_jobs,
    candidate_profile_status: (s) => s.candidate_profile_status,
    candidateByID: (s) => (id_to_match) => {
      console.log(s.candidates);
      for (const candidate of s.candidates) {
        if (candidate.detail.id === id_to_match) return candidate;
      }
      return null;
    },
    jobByID: (s) => (job_hashid) => {
      if (!s.candidate_jobs) return null;
      for (const job of s.candidate_jobs)
        if (job.job_hashid === job_hashid) return job;

      return null;
    },
    upcoming_meetings: (s) => {
      if (s.upcoming_meetings)
        return s.upcoming_meetings.filter(
          (eachMeeting) => new Date(eachMeeting.time.event_start) >= new Date()
        );
      else return s.upcoming_meetings;
    },
  },
  mutations: {
    RESET: (state, object_key = "all") => {
      if (object_key === "all") {
        for (const _key in initState) state[_key] = initState[_key];
        return;
      }
      state[object_key] = initState[object_key];
    },
    connected(state, flag) {
      state.is_connected = flag;
    },
    //List All Candidates
    index(state, { body: candidates, params }) {
      state.candidates = candidates;
    },
    index_job(state, { body: candidate_jobs, params }) {
      state.candidate_jobs = [...candidate_jobs];
      const _job_id = state.selected_job_hashid;
      if (_job_id) {
        let _job = { error: "Job Not Found!" };
        if (!candidate_jobs) {
          _job = { error: "No Jobs Available" };
          return null;
        } else
          for (const job of candidate_jobs)
            if (job.job_hashid === _job_id) _job = job;

        state.selected_job = _job;
      }
    },
    index_dashboard(state, { body: dashboard, params }) {
      state.dashboard = dashboard;
      state.isLoading = false;
    },
    job_filter_options(state, { body: job_filters, params }) {
      state.job_filters = job_filters;
    },
    edit(state, { body: candidate, params }) {
      state.selected_candidate = candidate;
    },
    update(state, { body: candidate, params }) {
      const index = state.candidates.findIndex((b) => b.id === candidate.id);
      if (index > -1) state.candidates.splice(index, 1, candidate);
      if (
        state.selected_candidate &&
        candidate.id === state.selected_candidate.id
      ) {
        state.selected_candidate = candidate;
        state.isLoading = false;
      }
    },
    apply_for_job(state, { body: updated_job, params }) {
      const job_idx = state.candidate_jobs.findIndex(
        (each_job) => each_job.job_hashid === updated_job.job_hashid
      );

      if(job_idx > -1) {
        state.candidate_jobs[job_idx].candidate_status  = updated_job.candidate_status;
        state.selected_job = state.candidate_jobs[job_idx];
      }
      // if (job_idx) state.candidate_jobs.splice(job_idx, 1, updated_job);
      // else state.candidate_jobs.push(updated_job);

    },
    unapply_for_job(state, { body: updated_job, params }) {
      const job_idx = state.candidate_jobs.findIndex(
        (each_job) => each_job.job_hashid === updated_job.job_hashid
      );

      if(job_idx > -1) {
        state.candidate_jobs[job_idx].candidate_status  = updated_job.candidate_status;
        state.selected_job = state.candidate_jobs[job_idx];
      }

      // if (job_idx) state.candidate_jobs.splice(job_idx, 1, updated_job);
      // else state.candidate_jobs.push(updated_job);

      // state.selected_job = updated_job;
    },
    index_recommended_jobs(state, { body: suggested_jobs, params }) {
      state.suggested_jobs = suggested_jobs;
    },
    complete_profile_by_resume(state, { body: candidate, params }) {
      state.selected_candidate = candidate;
      // console.log(state.selected_candidate);
    },
    upcoming_meeting(state, { body: upcoming_meetings, params }) {
      state.upcoming_meetings = upcoming_meetings;
    },
  },
  actions: {
    set_selected_job_hashid({ state }, job_hashid) {
      state.selected_job_hashid = job_hashid;
    },
    set_selected_job({ state, getters }, job_hashid) {
      state.selected_job = { ...getters.jobByID(job_hashid) };
    },
    set_loading({ state }, _flag) {
      state.isLoading = _flag;
    },
  },
};

export const mapCandidateActions = (data) =>
  mapActions(CANDIDATE_MODULE_NAME, data);
export const mapCandidateGetters = (data) =>
  mapGetters(CANDIDATE_MODULE_NAME, data);

export const mapCandidateMutations = (data) =>
  mapMutations(CANDIDATE_MODULE_NAME, data);

export default CandidateModule;
